import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Header from '../Partials/Header/Header';
import Sidebar from '../Partials/Sidebar/Sidebar';
import { db } from '../../firebase/firebase';
import { collection, addDoc, query, onSnapshot, orderBy, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase/firebase';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';




const Chat = () => {
    const navigate = useNavigate();
    const [showText, setShowText] = useState(false);
    const [isChatSlide, setIsChatSlide] = useState(false);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectAppointmentAppId, setSelectedAppointmentAppId] = useState();
    const [appointmentStatus, setAppointmentStatus] = useState();
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [imageUrls, setImageUrls] = useState({}); // Store image URLs
    const [file, setFile] = useState(null);  // Store selected file




    const location = useLocation();

    // Function to get query parameters
    const getQueryParam = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };
    // Example usage
    const oldPatient = getQueryParam('v'); // Get the value of 'v' parameter
    const appointmentChatId = getQueryParam('id')

    const handleUserClick1 = (user) => {
        setMessages([]);  // Clear previous messages
        setSelectedAppointmentId(user.id);  // Use the unique appointment ID
        setSelectedUser(user);  // Set the clicked user as selected user
        setSelectedAppointmentAppId(user.appId);
        if (window.innerWidth <= 991) {
            setIsChatSlide(true);
        }
    };

    const messagesRef = selectedAppointmentId
        ? collection(db, 'messages', selectedAppointmentId, 'Chats')
        : null;

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 991) {
                setIsChatSlide(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect(() => {
    //     const unsubscribe = onSnapshot(collection(db, 'appointments'), (snapshot) => {
    //         const fetchedUsers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //         setUsers(fetchedUsers);
    //         if (fetchedUsers.length > 0) {
    //             handleUserClick1(fetchedUsers[0]);  // Automatically select the first user
    //         }
    //     });
    //     return () => unsubscribe();
    // }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'appointments'), async (snapshot) => {
            const fetchedUsers = snapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(user => user.status == 'accepted' || user.status == 'finished');
            setUsers(fetchedUsers);

            if (appointmentChatId) {
                // Fetch the user by appointmentChatId
                const userDoc = await getDoc(doc(db, 'appointments', appointmentChatId));
                if (userDoc.exists() && userDoc.data().status == 'accepted' || userDoc.data().status == 'finished') {
                    const userData = { id: userDoc.id, ...userDoc.data() };
                    handleUserClick1(userData);  // Automatically select the user from the ID
                } else {
                    // If no user is found with the appointmentChatId, default to the first user
                    if (fetchedUsers.length > 0) {
                        handleUserClick1(fetchedUsers[0]);  // Automatically select the first user
                    }
                }
            } else if (fetchedUsers.length > 0) {
                handleUserClick1(fetchedUsers[0]);  // Automatically select the first user if no ID provided
            }
        });
        return () => unsubscribe();
    }, [appointmentChatId]);

    useEffect(() => {
        if (selectedAppointmentId) {
            const sessionRef = collection(db, 'messages', selectedAppointmentId, 'Chats');
            const q = query(sessionRef, orderBy('timestamp'));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const messagesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setMessages(messagesList);
            });
            return () => unsubscribe();
        }

    }, [selectedAppointmentId]);

    useEffect(() => {
        users.forEach(user => {
            if (!imageUrls[user.id]) {
                getUserImageUrl(user.id, user.gender);
            }
        });
    }, [users]);


    const getUserImageUrl = async (userId, gender) => {
        let imagePath = ``; // Default image path
        if (gender === 'male') {
            imagePath = `man.png`;
        } else if (gender === 'female') {
            imagePath = `woman.jpeg`;
        }

        try {
            const imageRef = ref(storage, imagePath); // Create reference to the image in Firebase Storage
            const url = await getDownloadURL(imageRef); // Get the download URL
            setImageUrls(prev => ({ ...prev, [userId]: url })); // Store the URL in state
        } catch (error) {
            console.error("Error fetching image from storage", error);
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);  // Store the selected file
    };



    const [AdminDetails, setAdminDetail] = useState(() => {
        var AdminDetails = localStorage.getItem("user");
        return AdminDetails ? JSON.parse(AdminDetails) : null;
    })

    const sendDoxy = () =>{
        try {
            if(selectedUser){
                var link = `https://doxy.me/drcwitcare`;
                var message = `We have send this mail to inform you that your appointment ${selectedUser.appId} is Accepted.\nYour appointment date and time is ${selectedUser.date}, ${selectedUser.time.start}. Please join call with below link\n${link}.`;
                var emailSubject = "Doxy Link For Call."
                const formdata = {subject:emailSubject, email:selectedUser.email,name:selectedUser.name,message:message};
                sendEmail(formdata);
            }
            toast.success('Sent Doxy Link Successfully');
           
        } catch (error) {
            console.log(error);
        }
    }
console.log(selectedUser);
    const sendEmail = (formData) => {
        emailjs.send(
          process.env.REACT_APP_SERVICE_ID,    // Replace with your EmailJS Service ID
          process.env.REACT_APP_TEMPLATE_ID_STATUS,   // Replace with your EmailJS Template ID
          formData,             // Data from the form fields
          process.env.REACT_APP_PUBLIC_KEY     // Replace with your EmailJS Public Key
        )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          // alert("Email sent successfully!");
        })
        .catch((error) => {
          console.error('FAILED...', error);
          // alert("Email failed to send.");
        });
      };

    const handleSendMessage = async () => {
        if (!message.trim() && !file) return;  // Don't allow sending if no message or file is provided

        let imageUrl = null;
        if (file) {
            // Upload the file to Firebase Storage
            const storageRef = ref(storage, `chat-images/${file.name}`);
            await uploadBytes(storageRef, file);

            // Get the download URL for the uploaded image
            imageUrl = await getDownloadURL(storageRef);
        }

        // Send message with or without image URL
        if (messagesRef && selectedUser) {
            const newMessage = {
                datetime: new Date().toLocaleString(),
                is_read: false,
                message: message || '',  // Add the message text
                imageUrl: imageUrl || null,  // Add image URL if uploaded
                senderId: "adminId",  // Use the dynamic sender ID
                receiverId: selectedUser.id,  // User as receiver
                receiverName: selectedUser.name,
                timestamp: serverTimestamp(),
            };

            await addDoc(messagesRef, newMessage);
            setMessage(''); // Clear the message input
            setFile(null);  // Clear the selected file
        }
    };
    const handleBackClick = () => {
        if (window.innerWidth <= 991) {
            setIsChatSlide(false);
        }
    };



    // console.log(appointmentChatId)


    return (
        <>
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Messages
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Messages</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className={`chat-window ${isChatSlide ? 'chat-slide' : ''}`}>
                                <div className="chat-cont-left">
                                    <div className="chat-header">
                                        <span>Chats</span>
                                        {/* <a href="javascript:void(0)" className="chat-compose">
                                            <i className="material-icons">control_point</i>
                                        </a> */}
                                    </div>
                                    <form className="chat-search">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fas fa-search" />
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" />
                                        </div>
                                    </form>
                                    <div className="chat-users-list">
                                        <div className="chat-scroll">
                                            {users.map((user) => (
                                                <a key={user.id} className="media sent" onClick={() => handleUserClick1(user)}>
                                                    <div className="media-img-wrap">
                                                        <div className={`avatar ${user.status}`}>
                                                            {/* <img src={user.image} alt="User" className="avatar-img rounded-circle" /> */}
                                                            {/* <img src={imageUrls[user.id] || 'default.jpg'} alt="User" className="avatar-img rounded-circle" /> */}
                                                            <img src="/assets/img/default_avatar.png" alt="User" className="avatar-img rounded-circle" />


                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div>
                                                            <div className="user-name">{user.name}</div>
                                                            <div className="user-last-chat">{user.lastMessage}</div>
                                                        </div>
                                                        <div>
                                                            <div className="last-chat-time block">{user.lastChatTime}</div>
                                                            {user.unreadMessages > 0 && (
                                                                <div className="badge badge-success badge-pill">{user.unreadMessages}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="chat-cont-right">
                                    <div className="chat-header">
                                        <a id="back_user_list" href="javascript:void(0)" className="back-user-list" onClick={handleBackClick}>
                                            <i className="material-icons">chevron_left</i>
                                        </a>
                                        <div className="media ">
                                            <div className="media-img-wrap">
                                                <div className="avatar">
                                                    {/* <img src={imageUrls[selectedUser?.id] || 'default.jpg'} alt="User" className="avatar-img rounded-circle" /> */}
                                                    <img src="/assets/img/default_avatar.png" alt="User" className="avatar-img rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div className="user-name">{selectedUser?.name}</div>
                                                {/* <div className="user-status">online</div> */}
                                            </div>
                                        </div>
                                        <div className="chat-options">
                                            {oldPatient == 1 ? (
                                                <Link to={`/patientAppointments?email=${selectedUser ? selectedUser.email : ''}`} className="mr-5">
                                                    <span className="fs-3">Appointment Details</span>
                                                </Link>
                                            ) : (
                                                <button className="btn btn-outline-info d-flex align-items-center" href="javascript:void(0)" data-toggle="modal" data-target="#voice_call" onClick={sendDoxy}>
                                                    <i className="material-icons mr-2">local_phone</i>
                                                    <span>Send Doxy Link</span>
                                                </button>

                                            )}
                                        </div>
                                    </div>

                                    <div className="chat-body">
                                        <div className="chat-scroll">
                                            {messages.length === 0 ? (
                                                <p className='d-flex justify-content-center align-items-center mt-5'>No messages to display</p>
                                            ) : (
                                                <ul className="list-unstyled">
                                                    {messages.map((msg, index) => (
                                                        <li key={index} className={`media ${msg.senderId === "adminId" ? "sent" : "received"}`}>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div>
                                                                        <p>{msg.message}</p>
                                                                        <ul className="chat-msg-info">
                                                                            <li>
                                                                                <div className="chat-time">
                                                                                    <span>{msg.timestamp ? new Date(msg.timestamp.seconds * 1000).toLocaleTimeString() : ''}</span>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>

                                        <div className="chat-footer">
                                            <div className="input-group">
                                                {/* <div class="input-group-prepend">
                                                    <div class="btn-file btn">
                                                        <input type="file"  onChange={handleFileChange} />
                                                        <i class="fa fa-paperclip"></i>
                                                    </div>
                                                </div> */}
                                                <input
                                                    type="text"
                                                    className="input-msg-send form-control"
                                                    placeholder="Type something"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn msg-send-btn" onClick={handleSendMessage}>
                                                        <i className="fab fa-telegram-plane" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chat;
